import "../finalProject.css";
import { useScroll, motion, useTransform } from "framer-motion";

function SaberMes() {
  const { scrollYProgress } = useScroll();
  const xOffset = useTransform(scrollYProgress, [0, 1], [0, -100]);
  const xOffset2 = useTransform(scrollYProgress, [0, 1], [0, 100]);
  return (
    <div className="design-page">
      <div className="div-intro">
        <h1 className="titulo-mas">Llibre de text interactiu</h1>
        <h1 className="subtitulo-mas">
          Transformant l'Educació amb Tecnologia
        </h1>
        <img
          className="first-img"
          alt="Portatil"
          src={"./img/portatil_saber_mes.png"}
        />
      </div>
      <div className="content-wrapper2">
        <div className="video-responsive">
          <iframe
            width="853"
            height="480"
            src={`https://www.youtube.com/embed/jSRiWG771pg`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </div>
        <div className="row-intro">
          <div className="col-left">
            <p style={{ marginLeft: "2vw", marginTop: "5vh" }}>
              Aquest breu vídeo d'un minut encapsula la extensa feina que hi ha
              darrere del meu projecte de final de carrera. Tot va començar amb
              el concepte de transformar un dels llibres de text més comuns
              utilitzats a les aules avui en dia en una eina d'aprenentatge més
              interactiva i dinàmica, sense abandonar la versió impresa
              tradicional. El resultat va ser una edició digital totalment
              innovadora i interactiva, complementada per un llibre físic. No
              obstant això, el llibre imprès ofereix una dimensió addicional
              proporcionant accés a contingut de realitat augmentada, millorant
              l'experiència d'aprenentatge.
            </p>
          </div>
          <div className="col-right">
            <img className="book-img" alt="Book" src={"./img/libro.png"} />
          </div>
        </div>
        <div className="row-laptops">
          <div className="col-left">
            <img
              className="laptop"
              alt="Single laptop book"
              src={"./img/Laptop.png"}
            />
          </div>
          <div className="col-right">
            <img
              className="two-laptops"
              alt="Laptops book"
              src={"./img/TwoLaptops.png"}
            />
          </div>
        </div>
        <h1 className="subtitle-mes">Investigació</h1>
        <div className="row-intro-centered">
          <div className="col-right">
            <img
              className="investigation-img"
              alt="Laptop investigation"
              src={"./img/Investigation.png"}
            />
          </div>
          <div className="col-left">
            <p>
              Aquest projecte va requerir una recerca rigorosa, ja que va entrar
              en terreny desconegut dins del camp de l'educació. Vaig iniciar el
              procés proporcionant una anàlisi contextual en profunditat de la
              situació educativa actual a Espanya. A més, es va realitzar una
              recerca extensa sobre les pràctiques contemporànies d'edició
              digital, l'ús de la realitat augmentada i les complexitats del
              disseny de l'experiència de l'usuari, un aspecte fonamental
              d'aquest projecte.
            </p>
          </div>
        </div>
        <h1 className="subtitle-mes">
          Disseny centrat <br></br>en l'usuari
        </h1>
        <p className="text-left">
          Per al desenvolupament d'aquesta proposta, es va triar una metodologia
          de disseny centrada en l'usuari. El procés va començar amb entrevistes
          per recopilar informació i conceptualitzar el llibre. Posteriorment,
          es van executar les següents etapes de desenvolupament: el dibuix
          inicial creat a Procreate, seguit de la creació de esquemes i maquetes
          en Figma. Aquesta metodologia va permetre una consideració minuciosa
          de les necessitats i expectatives de l'usuari a cada fase del
          projecte, assegurant que el resultat final fos altament interactiu i
          centrat en l'experiència de l'usuari.
        </p>
        <div className="row-laptops-animated">
          <motion.div
            className="laptop-animated-div-left"
            style={{ x: xOffset2 }}
          >
            <img
              className="animated-laptop"
              alt="Left laptop sketch"
              src={"./img/left.png"}
            />
            <h1 className="text-animated-laptop-mes">Sketch</h1>
          </motion.div>

          <div className="laptop-animated-div">
            <img
              className="animated-laptop"
              alt="Central laptop mockup"
              src={"./img/central.png"}
            />
            <h1 className="text-animated-laptop-mes">Mockup</h1>
          </div>

          <motion.div
            className="laptop-animated-div-right"
            style={{ x: xOffset }}
          >
            <img
              className="animated-laptop"
              alt="Right laptop wireframe"
              src={"./img/right.png"}
            />
            <h1 className="text-animated-laptop-mes">Wireframe</h1>
          </motion.div>
        </div>
        <div className="row-intro">
          <div className="col">
            <h1 className="col-titles">
              Test <br />
              d'usabilitat
            </h1>
            <img
              className="flex-image"
              alt="Usability"
              src={"./img/usabilidad.png"}
            />
            <p>
              El mockup va ser sotmesa a una rigorosa prova d'usabilitat, durant
              la qual es va demanar als participants que compleixessin sis
              tasques. Durant aquestes proves, es van identificar diverses
              qüestions d'usabilitat i es van abordar de manera immediata abans
              de passar a les fases de desenvolupament següents. Aquest
              compromís amb les proves d'usabilitat va permetre el
              perfeccionament del disseny, assegurant una experiència fluida i
              amigable per a l'usuari.
            </p>
          </div>
          <div className="col">
            <h1 className="col-titles">
              Evaluació <br />
              heurística
            </h1>
            <img
              className="flex-image"
              alt="Heuristic evaluation"
              src={"./img/heuristic.png"}
            />
            <p>
              El mockup va ser sotmès a proves contínues per avaluar encara més
              la interfície d'usuari. El procés de proves va incorporar les 10
              heurístiques de Nielsen, que van ajudar a identificar problemes
              addicionals d'usabilitat. Aquests problemes es van abordar i
              resoldre diligentment, reforçant el compromís de millorar
              l'experiència de l'usuari.
            </p>
          </div>
        </div>
        <h1 className="subtitle-mes">
          Desenvolupament <br />
          del projecte
        </h1>
        <div className="row-intro">
          <div className="col">
            <h1 className="col-titles">Programació</h1>
            <img
              className="flex-image"
              alt="Laptop programming"
              src={"./img/programing.png"}
            />
            <p style={{ marginTop: "5%" }}>
              Unity va servir com a plataforma de desenvolupament per donar vida
              al llibre, permetent la implementació sense problemes de totes les
              funcionalitats detallades durant les primeres etapes de
              conceptualització del llibre. El procés de desenvolupament va
              abraçar la programació d'elements interactius i la creació
              d'activitats.
            </p>
          </div>
          <div className="col">
            <h1 className="col-titles">
              Animacions <br />
            </h1>
            <img
              className="flex-image"
              alt="Laptop animations"
              src={"./img/after.png"}
            />
            <p style={{ marginTop: "5%" }}>
              Posteriorment, es van crear animacions per infondre dinamisme al
              llibre. Aquestes animacions es van crear utilitzant After Effects,
              amb l'objectiu de fer que els conceptes siguin més accessibles i
              captivadors per als nens. Aquesta fase del projecte no només va
              afegir una dimensió visual emocionant, sinó que també va reforçar
              el valor educatiu del contingut.
            </p>
          </div>
          <div className="col">
            <h1 className="col-titles">AR</h1>
            <img className="flex-image" alt="Revista 1" src={"./img/ar.png"} />
            <p style={{ marginTop: "5%" }}>
              Es va implementar una petita aplicació. Aquesta aplicació va
              permetre als usuaris accedir al contingut del llibre imprès
              mitjançant la realitat augmentada. Aquesta incorporació innovadora
              va millorar l'experiència d'aprenentatge tradicional proporcionant
              una forma interactiva i immersiva d'interactuar amb el material
              del llibre.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SaberMes;
