import Unity, { UnityContext } from "react-unity-webgl";
import "../demo.css";
import { useNavigate } from "react-router-dom"; // Importa useHistory
import { useEffect, useState } from "react";

function Demo() {
  const externalLink =
    "https://drive.google.com/file/d/1Q4VXwnZXfw-k9dK4Z7Ul0yiajwm8dvNZ/view?usp=sharing"; // URL externa
  const navigate = useNavigate();
  const [progression, setProgression] = useState(0);
  const [isLoading, setIsLoading] = useState(true); // Estado para controlar la visibilidad de la carga

  const unityContext = new UnityContext({
    loaderUrl: "BuildWebGL3.loader.js",
    dataUrl: "BuildWebGL3.data.gz",
    frameworkUrl: "BuildWebGL3.framework.js.gz",
    codeUrl: "BuildWebGL3.wasm.gz",
  });

  useEffect(() => {
    const progressListener = (progress) => {
      const roundedProgress = Math.round(progress * 100);
      setProgression(roundedProgress);

      if (roundedProgress === 100) {
        // Cuando el progreso llega al 100%, ocultar la carga
        setIsLoading(false);
      }
    };

    unityContext.on("progress", progressListener);
  }, [unityContext]);

  const handleCerrarJuego = async () => {
    await unityContext.quitUnityInstance();
    navigate("/saber-mes");
  };

  return (
    <div className="demo-page">
      <button onClick={handleCerrarJuego} className="boton-saber">
        <h5 className="btn-title">
          Vols saber <br />
          MÉS?
        </h5>
      </button>

      <a href={externalLink} target="_blank" rel="noopener noreferrer">
        <button className="boton-download">
          <h5 className="btn-title">
            DESCARREGAR
            <br /> versió completa
          </h5>
        </button>
      </a>
      {isLoading ? ( // Mostrar elementos de carga solo si isLoading es verdadero
        <div className="carga-page">
          {/* Barra de carga */}
          <div className="barra-container">
            <p>Loading... {progression}%</p>

            <div className="loading-bar">
              <div
                className="loading-progress"
                style={{ width: `${progression}%` }}
              ></div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <Unity
        style={{ width: "100%", height: "100vh" }}
        unityContext={unityContext}
      />
    </div>
  );
}

export default Demo;
