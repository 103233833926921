import React, { useState } from "react";
import "./contact.css";

const CustomFloatingTextArea = ({
  label,
  placeholder,
  name,
  value,
  onChange,
}) => {
  const [focused, setFocused] = useState(false);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = (event) => {
    if (event.target.value === "") {
      setFocused(false);
    }
  };

  return (
    <div className={`custom-floating-textarea ${focused ? "focused" : ""}`}>
      <textarea
        placeholder={placeholder}
        onFocus={handleFocus}
        onBlur={handleBlur}
        name={name}
        value={value}
        onChange={onChange}
      />
      <label>{label}</label>
    </div>
  );
};

export default CustomFloatingTextArea;
