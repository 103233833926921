import "./App.css";
import "./fonts.css";
import ResponsiveNavbar from "./Navbar";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./screens/Home";
import Contact from "./screens/Contact";
import Designs from "./screens/Designs";
import AppWeb from "./screens/AppWeb";
import Screen3d from "./screens/3d";
import FinalProject from "./screens/FinalProject";
import Demo from "./screens/Demo";
import SaberMes from "./screens/SaberMes";

function App() {
  return (
    <div className="App">
      <Router>
        <ResponsiveNavbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/design" element={<Designs />} />
          <Route path="/appWeb" element={<AppWeb />} />
          <Route path="/3d" element={<Screen3d />} />
          <Route path="/finaldegreeproject" element={<FinalProject />} />
          <Route path="/demo" element={<Demo />} />
          <Route path="/saber-mes" element={<SaberMes />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
