import "../AppWeb.css";
import { useScroll, motion, useTransform } from "framer-motion";

function AppWeb() {
  const { scrollYProgress } = useScroll();
  const xOffset = useTransform(scrollYProgress, [0, 1], [0, -50]);
  const xOffset2 = useTransform(scrollYProgress, [0, 1], [0, 50]);

  return (
    <div className="design-page">
      <div className="content-wrapper2">
        <h1 className="titulo-page">App & Web</h1>
        <div className="design">
          <div className="left-aligned">
            <h3 style={{ position: "relative", zIndex: 3 }}>Appo App</h3>
            <h4
              style={{
                fontSize: 40,
                paddingLeft: "2vw",
                paddingBottom: 0,
                marginBottom: 0,
                position: "relative",
                zIndex: 3,
              }}
            >
              Made with react-native
            </h4>
            <div className="col-icons">
              <img
                className="icons-programs"
                alt="Icono React"
                src={"./img/react.png"}
              />
            </div>
          </div>
          <div className="left-aligned-wide">
            <p style={{ marginLeft: "2vw" }}>
              This collaborative project served as our university's final
              assignment, where we developed a mobile application using React
              Native. Our goal was to create a realistic app experience by
              connecting it to the Unsplash API, allowing us to access a vast
              database of images. Prior to the development phase, we
              meticulously designed all the elements using Figma, ensuring a
              polished and thoughtful user interface before diving into the
              coding process. This project showcases our ability to work as a
              team, seamlessly integrating design and development to deliver a
              functional and visually appealing mobile application.
            </p>
          </div>
          <div className="app-row">
            <motion.img
              className="phone-left"
              alt="Phone screen categories"
              src={"./img/phoneCategories.png"}
              style={{ x: xOffset }}
            />

            <img
              className="phone-central"
              alt="Phone screen home"
              src={"./img/phoneCentral.png"}
            />
            <motion.img
              className="phone-right"
              alt="Phone screen user"
              src={"./img/phoneUser.png"}
              style={{ x: xOffset2 }}
            />
          </div>
        </div>
        {/* Segundo diseño */}
        <div className="design">
          <div className="right-aligned">
            <h3 style={{ position: "relative", zIndex: 3 }}>Family Tasks</h3>
            <h4
              style={{
                fontSize: 40,
                paddingLeft: "2vw",
                paddingBottom: 0,
                marginBottom: 0,
                position: "relative",
                zIndex: 3,
              }}
            >
              Made with flutter
            </h4>
            <div className="col-icons">
              <img
                className="icons-programs"
                alt="Icono Photoshop"
                src={"./img/flutter.png"}
              />
            </div>
            <p style={{ marginLeft: "2vw" }}>
              This collaborative project served as the final assignment for one
              of our university courses. Our team designed and developed a
              unique application that facilitates task management within a
              family unit. Utilizing Flutter and learning Dart for the first
              time, we embarked on this journey. To enable data storage and
              management, we even created our own Firebase instance. This
              project represents a significant milestone in our journey,
              demonstrating our ability to design, develop, and implement
              innovative solutions while gaining proficiency in new programming
              languages and technologies.
            </p>
            <img
              className="image-app-right"
              alt="App family tasks"
              src={"./img/familyApp.png"}
            />
          </div>
        </div>
        {/* Tercer diseño */}
        <div className="design">
          <div className="left-aligned">
            <h3 style={{ position: "relative", zIndex: 3 }}>Web Films</h3>
            <h4
              style={{
                fontSize: 40,
                paddingLeft: "2vw",
                paddingBottom: 0,
                marginBottom: 0,
                position: "relative",
                zIndex: 3,
              }}
            >
              Made with react
            </h4>
            <div className="col-icons">
              <img
                className="icons-programs"
                alt="Icono React"
                src={"./img/react.png"}
              />
            </div>
            <p style={{ marginLeft: "2vw" }}>
              This project was a web design endeavor that tasked me with
              integrating movie information from an API into a React-based web
              design. The challenge lay in seamlessly incorporating dynamic data
              into the user interface, providing an interactive platform for
              exploring films. This project highlighted my ability to blend
              design principles with technical skills, resulting in a
              captivating web experience that leveraged real-time data.
            </p>
          </div>
          <img
            className="image-computer"
            alt="Web AWUG"
            src={"./img/webAWUG.png"}
          />
        </div>
      </div>
      <div className="circle-design"></div>
      <div className="circle-app-web"></div>
    </div>
  );
}

export default AppWeb;
