import "../designs.css";
import ScreenNike from "../ScreenNike";

function Designs() {
  return (
    <div className="design-page">
      <div className="content-wrapper2">
        <h1 className="titulo-page">Design</h1>
        <div className="design">
          <div className="left-aligned">
            <h3 style={{ position: "relative", zIndex: 3 }}>Flyer</h3>
            <h4
              style={{
                fontSize: 40,
                paddingLeft: "2vw",
                paddingBottom: 0,
                marginBottom: 0,
                position: "relative",
                zIndex: 3,
              }}
            >
              Drive-in cinema
            </h4>
            <div className="col-icons">
              <img
                className="icons-programs"
                alt="Icono Photosghop"
                src={"./img/iconoPhotoshop.png"}
              />
            </div>
            <p style={{ marginLeft: "2vw" }}>
              This project is a flyer promoting a drive-in cinema event. What
              sets this composition apart is the deliberate use of a
              well-balanced grid system to harmoniously arrange the visual
              elements. The centerpiece of this design is the iconic cinema
              marquee, reminiscent of old-school movie billboards, adding a
              touch of nostalgia to the flyer. To create visual interest and
              draw the viewer's attention, I skillfully employed a palette of
              complementary colors, resulting in a striking contrast. This
              project demonstrates my ability to combine visual elements,
              producing an engaging promotional piece.
            </p>
          </div>
          <img className="image-poster" alt="poster" src={"./img/poster.png"} />
        </div>
        {/* Segundo diseño */}
        <div className="design">
          <div className="right-aligned">
            <h3 style={{ zIndex: 3, position: "relative" }}>Web design</h3>
            <h4
              style={{
                fontSize: 40,
                paddingLeft: "2vw",
                paddingBottom: 0,
                marginBottom: 0,
              }}
            >
              Nike's invented collaboration with Newton
            </h4>
            <div className="col-icons">
              <img
                className="icons-programs"
                alt="Icono Photoshop"
                src={"./img/iconoPhotoshop.png"}
              />
            </div>
            <p style={{ marginLeft: "2vw" }}>
              For this project, the challenge was to envision a collaboration
              between the renowned brand Nike and any known character or brand.
              My choice was to partner Nike with none other than Sir Isaac
              Newton, the iconic scientist who formulated the laws of
              gravitation and motion. Focusing on this two concepts to promote a
              unique line of sneakers that defied even gravity itself. In the
              design, I portrayed elements in a state of zero gravity, a result
              of the sneakers' incredible force.
            </p>
          </div>
          <div className="scene-nike-wrapper">
            <ScreenNike />
          </div>
        </div>
        {/* Tercer diseño */}
        <div className="design">
          <div className="left-aligned">
            <h3>Magazine</h3>
            <h4
              style={{
                fontSize: 40,
                paddingLeft: "2vw",
                paddingBottom: 0,
                marginBottom: 0,
              }}
            >
              Jewelry shop
            </h4>
            <div className="col-icons">
              <img
                className="icons-programs"
                alt="Icono Photoshop"
                src={"./img/iconoPhotoshop.png"}
              />
              <img
                className="icons-programs"
                alt="Icono inDesign"
                src={"./img/inDesign.png"}
              />
            </div>
          </div>
          <div className="image-row">
            <img
              className="magazine-img"
              alt="Revista 1"
              src={"./img/revista2.png"}
            />
            <img
              className="magazine-img"
              alt="Revista 2"
              src={"./img/revista3.png"}
            />
            <img
              className="magazine-img"
              alt="Revista 3"
              src={"./img/revista4.png"}
            />
          </div>
          <img
            className="magazine-in-front"
            alt="Revista abierta"
            src={"./img/revistaPrimerPlano.png"}
          />
          <div className="left-aligned">
            <p style={{ marginLeft: "2vw", marginTop: "5vh" }}>
              This project was created using Adobe InDesign to design a compact
              catalog for a jewelry brand. Earthy tones were chosen to convey
              the brand's elegance and sophistication. Each page features unique
              compositions, while sharing visual elements to create a cohesive
              visual narrative. This project showcases my proficiency in
              utilizing InDesign to craft visually stunning and harmonious print
              materials for branding and marketing purposes.
            </p>
          </div>
        </div>
        {/* Cuarto diseño */}
        <div className="separador" style={{ height: "15vh" }}></div>
        <div className="design">
          <div className="right-aligned">
            <h3 style={{ zIndex: 3, position: "relative" }}>Packaging</h3>
            <h4
              style={{
                fontSize: 40,
                paddingLeft: "2vw",
                paddingBottom: 0,
                marginBottom: 0,
              }}
            >
              Packaging targeted towards children
            </h4>
            <div className="col-icons">
              <img
                className="icons-programs"
                alt="Icono Photoshop"
                src={"./img/iconoPhotoshop.png"}
              />
            </div>
            <p style={{ marginLeft: "2vw" }}>
              This project exemplifies my commitment to designing for diverse
              target audiences. It involved the creation of packaging for a
              children's product, specifically slime. The choice of the
              packaging material was deliberate, allowing children to engage
              with it before purchase, transforming the act of buying into an
              immersive experience. Additionally, the packaging incorporates
              translucent areas that offer a sneak peek of the slime inside,
              building anticipation without revealing too much. The design
              employs vibrant colors and authentic-looking elements that are
              easily recognizable to captivate young audiences and pique their
              interest.
            </p>
          </div>
          <img
            className="image-poster-right"
            alt="Packaging slime"
            src={"./img/slime.png"}
          />
        </div>
      </div>
      <div className="circle-designs"></div>
    </div>
  );
}

export default Designs;
