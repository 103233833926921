import "../3d.css";

function Screen3d() {
  return (
    <div className="design-page">
      <div className="content-wrapper2">
        <h1 className="titulo-page">3D</h1>
        <div className="design">
          <div className="left-aligned">
            <h3 style={{ position: "relative", zIndex: 3 }}>Obsolescence</h3>
            <h4
              style={{
                fontSize: 40,
                paddingLeft: "2vw",
                paddingBottom: 0,
                marginBottom: 0,
                position: "relative",
                zIndex: 3,
              }}
            >
              3d animation
            </h4>
            <div className="col-icons">
              <img
                className="icons-programs"
                alt="Icono 3dsMax"
                src={"./img/3dsmax.jpg"}
              />
            </div>
          </div>
          <div className="left-aligned-wide">
            <p style={{ marginLeft: "2vw" }}>
              Obsolescence is a short film project that I created independently.
              This assignment, part of my university coursework, challenged me
              to craft a brief animation using toys as characters. The storyline
              was a product of my imagination: when the lights go out,
              electronic devices power down, and the toys come to life. Slowly,
              they start assisting one another in their quest to escape from
              their confinement. Just as they manage to break free, the lights
              come back on, and the toys return to their inanimate state.
              Through this short film, I aimed to convey the idea of the things
              we are leaving behind in the wake of advancing technology. It
              serves as a poignant reminder of the potential consequences of our
              increasing reliance on modern devices.
            </p>
          </div>
          <div className="video-responsive2">
            <iframe
              width="853"
              height="480"
              src={`https://www.youtube.com/embed/rLPkiPmvMt0`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          </div>
        </div>
        <div className="design">
          <div className="right-aligned">
            <h3 style={{ zIndex: 3, position: "relative" }}>Cleopatra</h3>
            <h4
              style={{
                fontSize: 40,
                paddingLeft: "2vw",
                paddingBottom: 0,
                marginBottom: 0,
              }}
            >
              3d model
            </h4>
            <div className="col-icons">
              <img
                className="icons-programs"
                alt="Icono 3dsmax"
                src={"./img/3dsmax.jpg"}
              />
              <img
                className="icons-programs"
                alt="Icono Maya"
                src={"./img/maya.png"}
              />
              <img
                className="icons-programs"
                alt="Icono Substance"
                src={"./img/substance.png"}
              />
              <img
                className="icons-programs"
                alt="Icono Zbrush"
                src={"./img/zbrush.ico"}
              />
            </div>
            <p style={{ marginLeft: "2vw" }}>
              One of my most ambitious 3D projects involved the complete
              creation of a character, from conceptualization to final rigging
              and animation. I began with the character's concept and brought it
              to life using ZBrush for the initial modeling, employing various
              techniques to optimize polygon count effectively. Subsequently, I
              meticulously textured the character's elements using Substance
              Painter, adding depth and realism to the design. The final steps
              included rigging and animation, accomplished using Maya.
            </p>
          </div>
          <div className="div-cleopatra">
            <img
              className="cleopatra-image"
              alt="Cleopatra 3d model"
              src={"./img/cleopatra1.png"}
            />
          </div>

          <img
            style={{ width: "100%", marginTop: "8%" }}
            alt="Process Cleopatra"
            src={"./img/proceso.png"}
          />
        </div>
      </div>

      <div className="circle-3d"></div>
    </div>
  );
}

export default Screen3d;
