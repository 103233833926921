import "../contact.css";
import React, { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import emailjs from "@emailjs/browser";
import CustomFloatingLabel from "../CustomFloatingLabel";
import CustomFloatingTextArea from "../CustomFloatingTextArea";

function Contact() {
  const form = useRef();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_a2c55ne",
        "template_6egfnyk",
        form.current,
        "OJp3TLPLYaPpJRD6G"
      )
      .then(
        (result) => {
          console.log(result.text);
          setEmail("");
          setName("");
          setMessage("");
          setResult("Message sent successfully.");
        },
        (error) => {
          console.log(error.text);
          setResult("Error sending message.");
        }
      );
  };
  return (
    <div className="contact-page">
      <div className="content-wrapper">
        <h1 className="titulo-page">Contact</h1>

        <div className="form-wrapper">
          <p className="tiny-text" style={{ zIndex: 100 }}>
            Feel free to contact and I will get back to you as soon as possible.
          </p>
          <Form ref={form} onSubmit={sendEmail} className="custom-form">
            <CustomFloatingLabel
              label="Email address"
              type="email"
              placeholder="name@example.com"
              name="user_email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></CustomFloatingLabel>

            <CustomFloatingLabel
              label="Name"
              type="text"
              placeholder="Your name"
              name="user_name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></CustomFloatingLabel>

            <CustomFloatingTextArea
              label="Message"
              placeholder="Leave your message here"
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></CustomFloatingTextArea>
            <div className="row-final d-flex align-items-start">
              <p style={{ fontSize: 9 }}>{result}</p>
              <Button className="custom-button" type="submit">
                Send
              </Button>
            </div>
          </Form>
        </div>
      </div>
      <div className="circle-page"></div>
    </div>
  );
}

export default Contact;
