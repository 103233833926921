import React, { Component } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

class ScreenNike extends Component {
  componentDidMount() {
    const scene = new THREE.Scene();

    let canvasWidth = window.innerWidth * 0.6;
    let canvasHeight = window.innerHeight * 0.6;

    const camera = new THREE.PerspectiveCamera(
      35,
      canvasWidth / canvasHeight,
      0.1,
      100
    );

    const renderer = new THREE.WebGLRenderer({
      canvas: document.querySelector("#screen"),
      alpha: true,
    });

    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(canvasWidth, canvasHeight); // Ajusta el tamaño del canvas
    camera.position.setZ(30);

    let model;
    let initialRotationY = 0;

    const loader = new GLTFLoader();
    loader.load("/models/nikeScreen.gltf", (gltf) => {
      model = gltf.scene; // Accede al objeto scene dentro del objeto cargado

      // Ajusta la posición, escala u otras propiedades de tu modelo según sea necesario
      model.position.set(5, 0, -40);
      const viewportWidth = window.innerWidth;
      if (viewportWidth > 900) {
        model.scale.set(
          canvasWidth * 0.015,
          canvasWidth * 0.015,
          canvasWidth * 0.015
        );
      } else if (viewportWidth > 768) {
        model.scale.set(
          canvasWidth * 0.022,
          canvasWidth * 0.022,
          canvasWidth * 0.022
        );
      } else if (viewportWidth < 500) {
        model.scale.set(
          canvasWidth * 0.03,
          canvasWidth * 0.03,
          canvasWidth * 0.03
        );
        model.position.set(5, 0, -40);
      } else if (viewportWidth < 768) {
        model.scale.set(
          canvasWidth * 0.025,
          canvasWidth * 0.025,
          canvasWidth * 0.025
        );
      } else {
        model.scale.set(
          canvasWidth * 0.017,
          canvasWidth * 0.017,
          canvasWidth * 0.017
        );
      }
      model.rotation.set(0, -Math.PI / 2, 0); // Por ejemplo, una rotación de 90 grados en el eje X

      initialRotationY = model.rotation.y;

      scene.add(model);
    });

    const pointLight = new THREE.PointLight(0xffffff);
    pointLight.position.set(20, -25, 15);
    pointLight.intensity = 200;
    pointLight.castShadow = true;

    const pointLight2 = new THREE.PointLight(0xffffff);
    pointLight2.position.set(-20, 5, 15);
    pointLight2.castShadow = true;
    pointLight2.intensity = 500;

    const pointLight3 = new THREE.PointLight(0xffffff);
    pointLight3.position.set(30, 0, 0);
    pointLight3.castShadow = true;
    pointLight3.intensity = 1000;

    const ambientLight = new THREE.AmbientLight(0xffffff);
    ambientLight.intensity = 2.5;
    scene.add(pointLight, pointLight2, pointLight3, ambientLight);

    scene.background = null;

    const handleResize = () => {
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      if (viewportWidth < 768) {
        canvasWidth = viewportWidth * 0.9;
        canvasHeight = viewportHeight * 0.5;
      } else {
        canvasWidth = viewportWidth * 0.6;
        canvasHeight = viewportHeight * 0.6;
      }
      camera.aspect = canvasWidth / canvasHeight;
      camera.updateProjectionMatrix();

      if (model) {
        if (viewportWidth > 900) {
          model.scale.set(
            canvasWidth * 0.015,
            canvasWidth * 0.015,
            canvasWidth * 0.015
          );
        } else if (viewportWidth > 768) {
          model.scale.set(
            canvasWidth * 0.022,
            canvasWidth * 0.022,
            canvasWidth * 0.022
          );
        } else if (viewportWidth < 500) {
          model.scale.set(
            canvasWidth * 0.03,
            canvasWidth * 0.03,
            canvasWidth * 0.03
          );
          model.position.set(5, 0, -40);
        } else if (viewportWidth < 768) {
          model.scale.set(
            canvasWidth * 0.025,
            canvasWidth * 0.025,
            canvasWidth * 0.025
          );
        } else {
          model.scale.set(
            canvasWidth * 0.017,
            canvasWidth * 0.017,
            canvasWidth * 0.017
          );
        }
      }
      renderer.setSize(canvasWidth, canvasHeight);
      renderer.render(scene, camera);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    function moveCamera() {
      const t = document.body.getBoundingClientRect().top;
      // if (t >= -600 && t <= 0)
      if (model) {
        model.rotation.y = initialRotationY - t * 0.00035;
      }

      renderer.render(scene, camera);
    }

    document.body.onscroll = moveCamera.bind(this);
  }

  render() {
    return <canvas id="screen"></canvas>;
  }
}

export default ScreenNike;
