import "../finalProject.css";
import { useScroll, motion, useTransform } from "framer-motion";

function FinalProject() {
  const { scrollYProgress } = useScroll();
  const xOffset = useTransform(scrollYProgress, [0, 1], [0, -100]);
  const xOffset2 = useTransform(scrollYProgress, [0, 1], [0, 100]);
  return (
    <div className="design-page">
      <div className="content-wrapper2">
        <h1 className="titulo-page">
          Final degree <br></br>project
        </h1>
        <div className="video-responsive">
          <iframe
            width="853"
            height="480"
            src={`https://www.youtube.com/embed/jSRiWG771pg`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </div>
        <div className="row-intro">
          <div className="col-left">
            <p style={{ marginLeft: "2vw", marginTop: "5vh" }}>
              This brief one-minute video encapsulates the extensive work that
              underlies my final degree project. It all began with the concept
              of transforming one of the textbooks commonly used in classrooms
              today into a more interactive and dynamic learning resource,
              without forsaking the traditional printed version. The result was
              a wholly innovative and interactive digital edition, complemented
              by a physical book. The printed book, however, offers an extra
              dimension by providing access to augmented reality content,
              enhancing the learning experience.
            </p>
          </div>
          <div className="col-right">
            <img className="book-img" alt="Book" src={"./img/libro.png"} />
          </div>
        </div>
        <div className="row-laptops">
          <div className="col-left">
            <img
              className="laptop"
              alt="Single laptop book"
              src={"./img/Laptop.png"}
            />
          </div>
          <div className="col-right">
            <img
              className="two-laptops"
              alt="Laptops book"
              src={"./img/TwoLaptops.png"}
            />
          </div>
        </div>
        <h1 className="subtitle">Investigation</h1>
        <div className="row-intro-centered">
          <div className="col-right">
            <img
              className="investigation-img"
              alt="Laptop investigation"
              src={"./img/Investigation.png"}
            />
          </div>
          <div className="col-left">
            <p>
              This project demanded rigorous research, as it ventured into
              uncharted territory within the education field. I initiated the
              process by providing an in-depth contextual analysis of the
              current education landscape in Spain. Furthermore, extensive
              research was conducted on contemporary digital publishing
              practices, the utilization of augmented reality, and the
              intricacies of user experience design, a pivotal aspect of this
              project.
            </p>
          </div>
        </div>
        <h1 className="subtitle">
          User-Centered <br></br>Design
        </h1>
        <p className="text-left">
          For the development of this proposal, a user-centered design
          methodology was chosen. The process began with interviews to gather
          information and conceptualize the book. Subsequently, the following
          development stages were executed: the initial sketch created in
          Procreate, followed by the creation of wireframes and mockups in
          Figma. This methodology allowed for a meticulous consideration of user
          needs and expectations at each project phase, ensuring that the final
          outcome was highly interactive and user-experience-centric.
        </p>
        <div className="row-laptops-animated">
          <motion.div
            className="laptop-animated-div-left"
            style={{ x: xOffset2 }}
          >
            <img
              className="animated-laptop"
              alt="Left laptop sketch"
              src={"./img/left.png"}
            />
            <h1 className="text-animated-laptop">Sketch</h1>
          </motion.div>

          <div className="laptop-animated-div">
            <img
              className="animated-laptop"
              alt="Central laptop mockup"
              src={"./img/central.png"}
            />
            <h1 className="text-animated-laptop">Mockup</h1>
          </div>

          <motion.div
            className="laptop-animated-div-right"
            style={{ x: xOffset }}
          >
            <img
              className="animated-laptop"
              alt="Right laptop wireframe"
              src={"./img/right.png"}
            />
            <h1 className="text-animated-laptop">Wireframe</h1>
          </motion.div>
        </div>
        <div className="row-intro">
          <div className="col">
            <h1>
              Usability <br />
              test
            </h1>
            <img
              className="flex-image"
              alt="Usability"
              src={"./img/usabilidad.png"}
            />
            <p>
              The mockup underwent rigorous usability testing, during which
              participants were asked to complete six tasks. Throughout these
              tests, various usability issues were identified and promptly
              addressed before proceeding to subsequent development phases. This
              commitment to usability testing allowed for the refinement of the
              design, ensuring a seamless and user-friendly experience.
            </p>
          </div>
          <div className="col">
            <h1>
              Heuristic <br />
              evaluation
            </h1>
            <img
              className="flex-image"
              alt="Heuristic evaluation"
              src={"./img/heuristic.png"}
            />
            <p>
              The mockup underwent continuous testing to further evaluate the
              user interface. The testing process incorporated Nielsen's 10
              heuristics, which helped uncover additional usability issues.
              These issues were diligently addressed and resolved, underscoring
              the commitment to enhancing the user experience.
            </p>
          </div>
        </div>
        <h1 className="subtitle">
          Project <br />
          development
        </h1>
        <div className="row-intro">
          <div className="col">
            <h1>Programming</h1>
            <img
              className="flex-image"
              alt="Laptop programming"
              src={"./img/programing.png"}
            />
            <p style={{ marginTop: "5%" }}>
              Unity served as the development platform for bringing the book to
              life, allowing for the seamless implementation of all the
              functionalities outlined during the earlier stages of the book's
              conceptualization. The development process encompassed the
              programming of interactive elements and the creation of unit
              activities.
            </p>
          </div>
          <div className="col">
            <h1>
              Animations <br />
            </h1>
            <img
              className="flex-image"
              alt="Laptop animations"
              src={"./img/after.png"}
            />
            <p style={{ marginTop: "5%" }}>
              Subsequently, animations were crafted to infuse dynamism into the
              book. These animations were created using After Effects, aimed at
              making the concepts more accessible and engaging for children.
              This phase of the project not only added an exciting visual
              dimension but also reinforced the educational value of the
              content.
            </p>
          </div>
          <div className="col">
            <h1>AR</h1>
            <img className="flex-image" alt="Revista 1" src={"./img/ar.png"} />
            <p style={{ marginTop: "5%" }}>
              A small app was implemented. This app enabled users to access the
              content of the printed book through augmented reality. This
              innovative addition enhanced the traditional learning experience
              by providing an interactive and immersive way to engage with the
              book's material.
            </p>
          </div>
        </div>
      </div>
      <div className="circle-final-project"></div>
      <div className="circle-final-project-2"></div>
    </div>
  );
}

export default FinalProject;
